import clsx from 'clsx';
import { ImageProps } from 'next/image';
import Link, { LinkProps } from 'next/link';
import React from 'react';

import { ImageWithPlaceholder } from '../ImageWithPlaceholder/ImageWithPlaceholder';

export type TAwardSingleProps = {
  title?: string;
  description?: string;
  logo: ImageProps | null;
  listTitle?: string;
  link?: LinkProps | null;
  isActive?: boolean;
  id?: string;
};

export const AwardSingle: React.FC<TAwardSingleProps> = ({
  title,
  description,
  logo,
  link,
  listTitle,
  isActive,
}) => {
  const slideClass = clsx({
    'opacity-100': isActive,
    'opacity-30': !isActive,
  });
  const slideContentClasses = clsx({
    'opacity-100': isActive,
    'opacity-0': !isActive,
  });

  return (
    <div className='group relative flex w-full max-w-[3rem] flex-col pb-20'>
      <div className='mb-4 h-4'>
        {listTitle && (
          <h3 className='text-sm font-semibold leading-normal text-control-950'>
            {listTitle}
          </h3>
        )}
      </div>
      {logo && (
        <div
          className={clsx(
            slideClass,
            'duration-800 transition-all lg:hover:opacity-100',
          )}
        >
          {link ? (
            <Link {...link}>
              <ImageWithPlaceholder
                key={title}
                {...logo}
                className='duration-800 h-12 w-[3rem] object-contain object-center transition-all'
              />
            </Link>
          ) : (
            <ImageWithPlaceholder
              key={title}
              {...logo}
              className='duration-800 h-12 w-[3rem] object-contain object-center transition-opacity'
            />
          )}
          <div
            className={clsx(
              'absolute left-0 right-0 top-auto flex flex-col gap-2 pt-6 transition-opacity duration-300 lg:group-hover:opacity-100',
              slideContentClasses,
            )}
          >
            {title && (
              <h3 className='min-w-[10.875rem] text-sm font-semibold leading-normal text-control-950'>
                {title}
              </h3>
            )}
            {description && (
              <p className='min-w-[11.688rem] text-sm leading-extra-loose leading-loose text-control-600'>
                {description}
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
