import { baseApi } from '../../store/store.constants';

export type TActiveCampaignForm = Record<string, string> & {
  formId: string;
};

export const formsApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    activeCampaignFormSubmission: build.mutation<
      { doc: TActiveCampaignForm; message: string },
      TActiveCampaignForm
    >({
      query: ({ formId, ...body }) => ({
        url: `/active-campaign-integrations/form/${formId}/submit`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  endpoints: { activeCampaignFormSubmission },
  useActiveCampaignFormSubmissionMutation,
} = formsApi;
