import { BaseSyntheticEvent, useCallback, useState } from 'react';
import { FieldValues, UseFormReturn, useForm } from 'react-hook-form';

import { useActiveCampaignFormSubmissionMutation } from './postActiveCampaignForm.query';

export type TUseFormSubmission = { formId: string; onSubmit?: () => void };

type TActiveCampaignFormReturnType = Omit<
  UseFormReturn<FieldValues>,
  'handleSubmit'
> & {
  isSubmitSuccessful: boolean;
  handleSubmit: (e?: BaseSyntheticEvent) => Promise<void>;
  resetState: () => void;
};

export const useActiveCampaignForm = ({
  formId,
  onSubmit,
}: TUseFormSubmission): TActiveCampaignFormReturnType => {
  if (!formId) {
    throw new Error(
      'Invalid form value provided, check if form exists or has proper access settings.',
    );
  }

  const [submitForm] = useActiveCampaignFormSubmissionMutation();
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
  const reactHookForm = useForm({
    mode: 'onBlur',
  });

  const handleFormSubmit = useCallback(
    async (formData: Record<string, string>): Promise<void> => {
      try {
        await submitForm({
          formId,
          ...formData,
        });

        onSubmit && onSubmit();

        reactHookForm.reset(undefined, {
          keepSubmitCount: true,
        });
      } catch (e) {
        console.error(e);
      }
    },
    [formId, reactHookForm, submitForm, onSubmit],
  );

  const resetState = useCallback(() => {
    reactHookForm.reset();
    // force reset of submit successful state (e.g. after modal close)
    setIsSubmitSuccessful(false);
  }, [reactHookForm]);

  return {
    ...reactHookForm,
    isSubmitSuccessful,
    handleSubmit: reactHookForm.handleSubmit(handleFormSubmit),
    resetState,
  };
};
