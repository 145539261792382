import { baseTailwindConfig } from '@front/shared';
import { useMedia } from 'react-use';

const slidesCountConfig: Record<'md' | 'lg' | 'xl' | 'sm', number> = {
  sm: 3,
  md: 8,
  lg: 12,
  xl: 16,
};

export const useMediaSlidesCount = (): number => {
  const isMd = useMedia(`(min-width: ${baseTailwindConfig.screens.md})`);
  const isLg = useMedia(`(min-width: ${baseTailwindConfig.screens.lg})`);
  const isXl = useMedia(`(min-width: ${baseTailwindConfig.screens.xl})`, true);

  let slidesCount;
  switch (true) {
    case isXl:
      slidesCount = slidesCountConfig.xl;
      break;
    case isLg:
      slidesCount = slidesCountConfig.lg;
      break;
    case isMd:
      slidesCount = slidesCountConfig.md;
      break;
    default:
      slidesCount = slidesCountConfig.sm;
      break;
  }

  return slidesCount;
};
